import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import "./_NewDepForm.scss";
import { useDispatch } from "react-redux";
import { updateDepartment } from "../../../slices/departement";
import UpdateDepForm from "./AddDepComponent";
import Notice from "../../../components/Notice";

function UpdateDep(props) {
  const { handleClose, id, setNoticeDelete, noticeDelete } = props;
  const [imageName, setImageName] = useState();

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.modals.modals[0]);
  const { deleteDepartmentStatus, ErrorDeleteDepartment } = useSelector(
    (state) => state.department
  );

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });
  const [fieldChange, setFieldChange] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);
  const [isUpdate, setUpdate] = useState(true);
  const [myImg, setMyImg] = useState(true);

  const initialValues =
    data?.img === imageName
      ? { name: data?.name, description: data?.description, img: data?.img }
      : { name: data?.name, description: data?.description };

  const onSubmit = async (values, submitProps) => {
    const Data = {
      name: values?.name,
      img: values?.img,
      description: values?.description,
    };
    fieldChange &&
      (await dispatch(updateDepartment({ ...Data, id: data?.id })).then(
        (res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        }
      ));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formik) => {
        formik.dirty === true && setFieldChange(true);
        return (
          <>
            {isUpdate && (
              <Notice
                open={notice.open}
                text={notice.text}
                state={notice.state}
              />
            )}
            <Form>
              <UpdateDepForm
                 setFieldChange={setFieldChange}
                setNoticeDelete={setNoticeDelete}
                noticeDelete={noticeDelete}
                setMyImg={setMyImg}
                myImg={myImg}
                img={data?.img}
                handleClose={handleClose}
                id={id}
                deleteDepartmentStatus={deleteDepartmentStatus}
                ErrorDeleteDepartment={ErrorDeleteDepartment}
                setNotice={setNotice}
                notice={notice}
                formik={formik}
                setUpdate={setUpdate}
                isUpdate={isUpdate}
                setImageName={setImageName}
                className={
                  !formik.isValid || formik.isSubmitting
                    ? " blueButton disabled-button "
                    : "blueButton "
                }
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default UpdateDep;
